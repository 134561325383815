import React from 'react';
import { Page, Section } from 'src/components';
import { $t } from 'src/utils';

export default function Home() {
  return (
    <Page
      navBorder={false}
      metadata={{
        title: 'About Catch',
        image: '/img/og/about.png',
        path: 'about',
        description: $t({
          id: 'about.title',
          defaultMessage: 'Finding the right benefits should be simple.',
        }),
      }}
      // prefooter={
      //   <Section
      //     inset
      //     bg="var(--g-cerise-text)"
      //     narrow
      //     center
      //     dark
      //     title={$t({
      //       id: 'about.prefooter-title',
      //       defaultMessage: "See the world the way we do?"
      //     })}
      //     subtitle={$t({
      //       id: 'about.prefooter-subtitle',
      //       defaultMessage: `There’s nothing like working on a mission-driven product with a talented
      //       team. If you’re looking for your next challenge, we’d love to hear from you.`
      //     })}
      //   >
      //     <Link to="/careers">{$t({ id: 'more', defaultMessage: 'More' })} &#8594;</Link>
      //   </Section>
      // }
    >
      <Section narrow large>
        <h1
          className="mask-gradient m"
          style={{ backgroundImage: 'var(--g-cerise-text)', paddingBottom: 24 }}
        >
          {$t({
            id: 'about.title',
            defaultMessage: 'Finding the right benefits should be simple.',
          })}
        </h1>
        <h3 className="l">
          {$t({
            id: 'about.p-1',
            defaultMessage:
              'You shouldn’t have to sift through hundreds of plans to figure out the right one for your needs. You shouldn’t have to speak legalese to get great healthcare. And you shouldn’t ever have to worry if you’ll be covered when the unexpected happens.',
          })}
        </h3>
        <h3
          className="mask-gradient m"
          style={{ backgroundImage: 'var(--g-cerise-text)', paddingBottom: 24 }}
        >
          {$t({
            id: 'about.p-2',
            defaultMessage: 'Catch helps you build your safety net the simple way.',
          })}
        </h3>
        <h3 className="l">
          {$t({
            id: 'about.p-3',
            defaultMessage:
              'We know benefits inside and out. Think of us as your personal HR department, the friendly experts who help you get corporate-style benefits without the corporate job. ',
          })}
        </h3>
        <h3 className="l">
          {$t({
            id: 'about.p-4',
            defaultMessage:
              'You share what’s important to you, then we find you the best plans for the best price based on your needs, goals, and budget. Let us decipher the fine print. You have enough on your plate already.',
          })}
        </h3>
        <h3 className="l">
          {$t({
            id: 'about.p-5',
            defaultMessage:
              'And if you ever run into any snags, our Benefits Advisors will hop on the phone to talk you through choosing a plan and signing up, step by step. Because sometimes you just want a human to hold your hand. ',
          })}
        </h3>
        <h3
          className="mask-gradient m"
          style={{ backgroundImage: 'var(--g-cerise-text)', paddingBottom: 24 }}
        >
          {$t({
            id: 'about.p-6',
            defaultMessage: 'We do the research, you get the benefits. It’s as simple as that.',
          })}
        </h3>
      </Section>
    </Page>
  );
}
